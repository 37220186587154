<template>
  <a-modal
    width="800px"
    v-model="isShow"
    title="挽联信息"
    :mask-closable="false"
    :body-style="{ paddingLeft: 100 }"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>
    <div v-if="elegiacInformation != null && elegiacInformation.length > 0">
      <a-row>
        <a-col
          :span="16"
          :offset="2"
          v-for="(elegiac, index) in elegiacInformation"
          :key="index"
        >
          <a-descriptions
            :column="1"
            class="custom-descriptions custom-descriptions-max-10"
          >
            <a-descriptions-item label="亲属方">
              <span style="word-break: break-all; width: 300px">{{ elegiac.family_text }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="敬赠人(男)">
              <span style="word-break: break-all; width: 300px">{{ elegiac.male_giver_name }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="敬赠人(女)">
              <span style="word-break: break-all; width: 300px">{{ elegiac.female_giver_name }}</span>
            </a-descriptions-item>
            <a-descriptions-item>
              <span style="word-break: break-all; width: 300px">{{ elegiac.with_family_type_display }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="与逝者关系">
              <span style="word-break: break-all; width: 300px">{{ elegiac.relation }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="称呼逝者">
              <span style="word-break: break-all; width: 300px">{{ elegiac.call_death }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="逝者姓名">
              <span style="word-break: break-all; width: 300px">{{ elegiac.death_name }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="逝者性别">
              <span style="word-break: break-all; width: 300px">{{ elegiac.sex }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="挽联内容">
              <span style="word-break: break-all; width: 300px">{{ elegiac.elegy_content }}</span>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider v-show="elegiacInformation && index !== elegiacInformation.length-1" />
        </a-col>
      </a-row>
    </div>
    <div v-else style="text-align: center">
      <img src="https://res.anxiaotang.com/assets/76f88337-c052-4428-8649-343b7653925f" style="height: 150px;width: 100%">
      <span>暂无数据</span>
    </div>
  </a-modal>
</template>

<script>

export default {
  name: 'ElegiacInformation',
  props: {
    elegiacInformation: {
      type: Array,
      default: function() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      data: {},
      loading: true
    }
  },
  created() {
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>

/deep/
.ant-descriptions-item {
  display: flex;

  .ant-descriptions-item-content {
    display: flex;
  }

  .ant-descriptions-item span {
    display: table-cell;
  }
}

</style>
